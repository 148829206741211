@import '../../styles/common/layout';
@import '~@blueprintjs/core/lib/scss/variables';

.baseCard {
  // NOTE: display: flex is required to count margins for offsetHeight
  @include column;

  margin: var(--endpoint-shadow-size);
  border-radius: 10px;
  user-select: none;

  transition:
    transform ($pt-transition-duration * 2) $pt-transition-ease,
    box-shadow ($pt-transition-duration * 2) $pt-transition-ease;

  :global(button.bp4-button) {
    margin: 7px 20px 14px;
  }
}
