@import '~/styles/common/layout';

.app {
  @include column;
  flex-grow: 1;

  position: relative;
  overflow: hidden;

  width: 100%;
  height: 100%;

  .map {
    box-shadow: inset 0 0 23px rgb(8 44 65 / 12%);
    background-color: #f0f4f8;

    flex-grow: 1;
  }
}
