.container {
  flex: 1;
  flex-shrink: 1;
  width: auto;
  min-width: 0;
  max-width: 720px;

  :global(.bp3-popover-target) {
    width: 100%;
  }

  :global(.bp3-input-ghost) {
    font-size: 14px;
  }

  :global(.bp3-tag-input-values) {
    display: flex;
    flex-wrap: nowrap;
    overflow: hidden;
    padding-right: 5px;
  }

  :global(.bp3-text-overflow-ellipsis.bp3-fill) {
    overflow: visible;
  }

  :global(.bp3-tag) {
    padding: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 0 !important;
    padding-right: 4px !important;
    background-color: #eaeaea !important;
  }
}

.tag {
  display: flex;
  align-items: center;
  line-height: 15px;
  font-size: 13px;
  color: #92939f;

  .direction {
    display: flex;
    align-items: center;
    position: relative;
    top: -1px;
    padding: 0 5px;
    margin-right: 7px;
    border-top: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    border-right: 1px solid #eaeaea;
    border-radius: 3px 0 0 3px;
    background-color: #fff;

    .icon {
      position: relative;
      top: -2.5px;
    }

    .label {
      display: block;
      margin-left: 5px;
      margin-right: 5px;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.from {
        color: #468706;
      }

      &.to {
        color: #065a8d;
      }
    }
  }

  .body {
    display: flex;
    position: relative;

    .kind,
    .query,
    .meta {
      display: block;
    }

    .negative {
      color: #d00;
      margin: 0 2px;
    }

    .kind {
      color: #3b3c42;
    }

    .query {
      color: #595a63;
    }

    .meta {
      margin-left: 7px;
      font-style: italic;
    }

    .separator {
      margin: 0 2px;
      color: #999;
    }
  }
}
