@import '~/styles/common/layout';

// Thanks to Martin Wolf for blinking css dots
// https://martinwolf.org/before-2018/blog/2015/01/pure-css-savingloading-dots-animation/

@mixin setup-animation($name) {
  animation-name: $name;
  animation-duration: 1.4s;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

@mixin circle-color($cls, $clr) {
  &#{$cls} {
    .circle {
      background-color: $clr;
    }
  }
}

$receiving-color: #71bd25;
$reconnecting-color: #bd6225;
$stopped-color: $reconnecting-color;
$idle-color: #c7c7c7;

.wrapper {
  @include row(flex-start, center);

  .circle {
    @include exact-size(10px, 10px);
    border-radius: 50%;
  }

  .label {
    margin-left: 7px;

    font-size: 14px;
    font-weight: 500;
    color: #7c8389;

    span.dots {
      & > span {
        @include setup-animation(streaming-indicator-dot);
      }

      & > span:nth-child(2) {
        animation-delay: 0.2s;
      }

      & > span:nth-child(3) {
        animation-delay: 0.4s;
      }
    }

    .dotDivider {
      margin-left: 8px;
      margin-right: 8px;
    }

    .flowsLabel,
    .nodesLabel {
      color: #5c7080;
    }
  }

  @include circle-color('', $idle-color);
  @include circle-color('.idle', $idle-color);
  @include circle-color('.receiving', $receiving-color);
  @include circle-color('.reconnecting', $reconnecting-color);
  @include circle-color('.stopped', $stopped-color);

  &.circleAnimated {
    .circle {
      @include setup-animation(streaming-indicator-circle);
    }
  }
}

@keyframes streaming-indicator-dot {
  0% {
    opacity: 0.2;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.2;
  }
}

@keyframes streaming-indicator-circle {
  0% {
    opacity: 0.75;
  }

  20% {
    opacity: 1;
  }

  100% {
    opacity: 0.75;
  }
}
