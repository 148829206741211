.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.label {
  margin-top: 20px;
  font-size: 14px;
  font-weight: 500;
  color: #5c7080;
}
