@import '~/styles/common/layout';

.content {
  background-color: rgb(255 255 255 / 90%);
}

.serviceMapCardBackplate {
  box-shadow: none;
  background-color: var(--hubble-ui-svcmap-card-bg-primary);

  &:hover {
    box-shadow: none;
  }

  &.active {
    cursor: grabbing;
    box-shadow: var(--hubble-ui-card-boxshadow-active);
    opacity: 0.9;
    transition-duration: 0;
  }
}

.serviceMapCardBackground {
  background-color: var(--hubble-ui-svcmap-card-bg-primary);
  border: 2px solid var(--hubble-ui-svcmap-card-border);
}

.serviceMapCardForeground {
  box-shadow: none;
  background-color: transparent;
}

.labels {
  @include row;
  flex-flow: wrap;

  padding: 10px 0;
  margin: 0 20px;

  .label {
    @include ellipsis-limit;

    border-radius: 5px;
    padding: 5px 10px;

    color: #929292;
    background: #eaeaea;

    margin-bottom: 5px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.clusterNameLabel {
  @include ellipsis-limit;
  margin: 10px 20px 0;
  padding: 0 15px 0 30px;

  font-size: 14px;
  line-height: 32px;
  font-weight: 500;
  color: #000;

  background: url('icons/misc/icon-cube.png') no-repeat;
  background-size: 13.51px 14.8px;
  background-position-y: center;
  background-position-x: 10px;

  &:last-child {
    margin-bottom: 10px;
  }
}

.accessPoints {
  @include column;

  margin: 0 20px;
  padding: 15px;

  border: 1px solid #d4d4d4;
  border-radius: 10px;

  &:last-child {
    margin-bottom: 20px;
  }

  .l7groups {
    margin-top: 7px;
  }

  .endpointsLimited {
    padding-left: 10px;
    margin-top: 5px;

    color: #929a9f;
  }
}

.accessPoints + .labels {
  margin-top: 0;
}
